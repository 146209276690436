import React, { useState } from "react";
import { styled } from "twin.macro";
import { ReactComponent as CaretIcon } from "../../images/ICON_GREY_EXPAND.svg";

const SponsorQuestion = styled.li`
  &:first-of-type {
    button {
      border-top: 1px solid rgba(124, 135, 149, 0.3);
    }
  }
`;

const Header = styled.button`
  font-family: inherit;
  &:focus {
    outline: 1px solid transparent;
  }
  cursor: pointer;
  border: 0;
  border-bottom: ${props =>
    props.expanded ? `0` : `1px solid rgba(124, 135, 149, 0.3)`};
  color: ${props =>
    props.expanded ? `var(--color-magenta)` : `var(--color-grey)`};
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;

  padding: 10px 0;

  p {
    text-decoration: ${props => (props.expanded ? `underline` : `none`)};
  }

  svg {
    transition: transform 0.5s ease;
    transform: ${props =>
      props.expanded ? `rotate(-180deg)` : `rotate(0deg)`};
    fill: ${props =>
      props.expanded ? `var(--color-magenta)` : `var(--color-grey)`};
    height: 15px;
    width: 17px;
  }

  @media (min-width: 768px) {
    padding: 15px 0;

    svg {
      height: 25px;
      width: 27px;
    }
  }

  @media (min-width: 1024px) {
    padding: 17px 0;

    svg {
      height: 30px;
      width: 32px;
    }
  }
`;

const Question = styled.p`
  font-weight: 600;

  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.17px;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.71px;
  }

  @media (min-width: 1024px) {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: -1.57px;
  }
`;

const Answer = styled.div`
  // max-height: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  p {
    // max-height: 0;
    opacity: 0;
    transition: all 0.5s ease;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.27px;
    pointer-events: none;
  }

  p,
  ul,
  li {
    margin-bottom: 1em;
  }

  strong {
    font-weight: 600;
    text-transform: uppercase;
  }

  &.expand {
    // max-height: 100vh;
    padding: 1rem 0;
    height: inherit;
    opacity: 1;
    visibility: visible;
    border-bottom: 1px solid rgba(124, 135, 149, 0.3);

    p {
      // max-height: 100vh;
      opacity: 1;
      pointer-events: all;
    }
  }

  @media (min-width: 768px) {
    &.expand {
      padding: 15px 0;
    }
    p {
      font-size: 19px;
      line-height: 29px;
      letter-spacing: -0.36px;
    }
  }

  @media (min-width: 1024px) {
    &.expand {
    }
    p {
      font-size: 22px;
      line-height: 34px;
      letter-spacing: -0.41px;
    }
  }
`;

const SponsorQuestionComponent = ({ onClick, expanded, question }) => {
  return (
    <SponsorQuestion>
      <Header expanded={expanded} onClick={onClick}>
        <Question>{question.question}</Question>
        <CaretIcon />
      </Header>
      <Answer
        className={expanded ? "expand" : ""}
        dangerouslySetInnerHTML={{ __html: question.answer }}
      ></Answer>
    </SponsorQuestion>
  );
};

export default SponsorQuestionComponent;
