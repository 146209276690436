import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import Context from "../../context/Context";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/seo";
import PageHero from "../../components/PageSections/PageHero";
import SponsorshipGuide from "../../components/PageSections/SponsorshipGuide";
import CopyAndButton from "../../components/PageSections/CopyAndButton";
import GetInvolved from "../../components/PageSections/GetInvolved";

const PageContent = styled.section`
  ${tw`

  `};
  // padding: 60px 48px;

  // p {
  //   font-size: 14px;
  //   line-height: 21px;
  //   letter-spacing: -0.27px;
  //   margin-bottom: 1em;
  // }

  // @media (min-width: 768px) {
  //   padding: 90px 118px;

  //   p {
  //     font-size: 19px;
  //     line-height: 29px;
  //     letter-spacing: -0.36px;
  //   }
  // }

  // @media (min-width: 1024px) {
  //   padding: 120px 297px;

  //   p {
  //     max-width: 900px;
  //     font-size: 22px;
  //     line-height: 34px;
  //     letter-spacing: -0.41px;
  //     margin: 0 auto 1em;
  //   }
  // }
`;

const GetInvolvedSponsor = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(
        filter: {
          parent_element: { slug: { eq: "get-involved" } }
          slug: { eq: "sponsor" }
        }
      ) {
        nodes {
          title
          slug
          parent_element {
            slug
          }
          acf {
            hero {
              title
              cta_type
              cta_text
              cta_link
              copy
              artist {
                age
                credit
              }
              art {
                source_url
              }
            }
            apply
            sponsorship_guide {
              question
              answer
            }
          }
          content
          yoast_meta {
            yoast_wpseo_social_defaults {
              og_frontpage_image {
                source_url
              }
            }
            yoast_wpseo_metadesc
            yoast_wpseo_facebook_title
            yoast_wpseo_facebook_image {
              source_url
            }
            yoast_wpseo_facebook_description
            yoast_wpseo_twitter_title
            yoast_wpseo_twitter_image {
              source_url
            }
            yoast_wpseo_twitter_description
          }
        }
      }
    }
  `);
  const { acf, content, yoast_meta, title } = data?.allWordpressPage?.nodes[0];

  const { setModal } = useContext(Context);

  const {
    yoast_wpseo_metadesc: seo_desc,
    yoast_wpseo_facebook_title: seo_fb_title,
    yoast_wpseo_facebook_description: seo_fb_desc,
    yoast_wpseo_twitter_title: seo_tw_title,
    yoast_wpseo_twitter_description: seo_tw_desc
  } = yoast_meta;

  const seo_image =
    yoast_meta.yoast_wpseo_social_defaults?.og_frontpage_image?.source_url ||
    "";
  const seo_fb_image =
    yoast_meta.yoast_wpseo_facebook_image?.source_url || seo_image;
  const seo_tw_image =
    yoast_meta.yoast_wpseo_twitter_image?.source_url || seo_image;

  return (
    <Layout>
      <SEO
        title={title}
        description={seo_desc}
        image={seo_fb_image}
        metaTitleFacebook={seo_fb_title}
        metaImageFacebook={seo_fb_image}
        metaDescFacebook={seo_fb_desc}
        metaTitleTwitter={seo_tw_title}
        metaImageTwitter={seo_tw_image}
        metaDescTwitter={seo_tw_desc}
      />
      <PageHero {...acf.hero} />
      <div className="section-border">
        <div className="container py-10 lg:py-20">
          <div className="flex">
            <PageContent
              className="w-full md:w-3/4 mx-auto"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
      <SponsorshipGuide sponsorship_guide={acf.sponsorship_guide} />
      <GetInvolved />
    </Layout>
  );
};

export default GetInvolvedSponsor;
