import React, { useState } from "react";
import { styled } from "twin.macro";
import SponsorQuestion from "./SponsorQuestion";

const List = styled.ul`
  // border-top: 1px solid rgba(124, 135, 149, 0.3);
  padding: 60px 48px;

  @media (min-width: 768px) {
    padding: 90px 118px;
  }

  @media (min-width: 1024px) {
    padding: 118px 0px;
    max-width: 900px;
    margin: 0 auto;
  }
`;

const SectionTitle = styled.p`
  font-weight: 600;

  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.15px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 15px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.16px;
    margin-bottom: 17px;
  }
`;

const SponshorshipGuide = ({ sponsorship_guide }) => {
  const [expandedItem, setExpandedItem] = useState(sponsorship_guide[0]);
  return (
    <List>
      <SectionTitle>Sponsorship Guide</SectionTitle>
      {sponsorship_guide.map((question, index) => (
        <SponsorQuestion
          question={question}
          key={index}
          expanded={question === expandedItem}
          onClick={() => setExpandedItem(question)}
        />
      ))}
    </List>
  );
};

export default SponshorshipGuide;
